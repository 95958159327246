import { Element, html, css } from "../element";

import WebAudio from '../../nodes/audio/'
import * as nodes from './nodes'

import "./intro"
import "./canvas"
import "./alert"


const sounds = {
  ambient: {
    agali: {
      url: "https://westridgein.s3.us-east-1.amazonaws.com/sfx/jungle/agali.mp3",
      source: null,
    },
    kollamkadu: {
      url: "https://westridgein.s3.us-east-1.amazonaws.com/sfx/jungle/kollamkadu.mp3",
      source: null,
    },
    koravampadi: {
      url: "https://westridgein.s3.us-east-1.amazonaws.com/sfx/jungle/koravampadi.mp3",
      source: null,
    },
    puliyara: {
      url: "https://westridgein.s3.us-east-1.amazonaws.com/sfx/jungle/puliyara.mp3",
      source: null,
    },
    sholayur: {
      url: "https://westridgein.s3.us-east-1.amazonaws.com/sfx/jungle/sholayur.mp3",
      source: null,
    },
    vatlakki: {
      url: "https://westridgein.s3.us-east-1.amazonaws.com/sfx/jungle/vatlakki.mp3",
      source: null,
    }
  }


}




const styles = css`
  :host {
    display: block;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    --harmony-color-white-800: #ffffff;
    --harmony-color-blue-800: #24ccff;

    --harmony-font: var(--headline-font, "Inter", sans-serif);

    font-family: var(--harmony-font);
    pointer-events: none;
  }
  .container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 64px;
    margin: -12px;
    pointer-events: none;

    color: var(--color-eternity, #000);
    
  }

  harmony-intro {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 300;
  }

  harmony-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 400;
    pointer-events: none;
   
    
  }
  harmony-alert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    pointer-events: none;
  }

  .audio {
    position: absolute;
    bottom: 15px;
    left: 30px;
    z-index: 300;
    pointer-events: all;
    
  }

  .toggleplay {
    width: 30px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    background: none;

  }
  .toggleplay svg {
    width: 100%;
    height: 100%;
    fill: var(--color-fire);
  }
    .toggleplay {

    }

  .mask {
    border-radius: 64px;
    border: 24px solid white;
    outline: 24px solid white;
    position: absolute;
    left: -12px;
    top: -12px;
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    z-index: 600;
    pointer-events: none;
    box-sizing: border-box;
  }
`;

const template = function (elem) {
  const { context } = elem;
  return html`
    <div class="container"> 
      <div class="audio">
        <audio crossorigin="anonymous" src="https://westridgein.s3.us-east-1.amazonaws.com/media/undying-sun.mp3"></audio>
        <div class="player">
          <div class="toggleplay" @click=${(e) => elem.togglePlay(e)}>
            <svg viewBox="0 0 101 101">
              <polygon class="cls-2" points="29.33 5 73.94 49.61 29.33 94.22 29.33 5"/>
            </svg>
          </div>
        </div>
      </div>
      <harmony-intro></harmony-intro>
      <harmony-canvas .context="${context}"></harmony-canvas>
      <harmony-alert></harmony-alert>
      <div class="mask"></div>
    </div>
  `;
};





const setupWebAudio = async (elem, file) => {
      const webaudio = new WebAudio(
        {
          nodes: [
            {
              fname: 'gain',
              id: 'volume',
              ins: [{ id: 'src', i: 'in' }],
              props: {
                config: {
                  gain: 0.5
                }
              }
            },
            {
              fname: 'beat',
              id: 'beat',
              ins: [{ id: 'volume', i: 'in' }],
              props: {
                config: {
                  gain: 1
                }
              }
            },
            {
              fname: 'analyser',
              id: 'analyser',
              ins: [{ id: 'volume', i: 'in' }],
              props: {
                config: {
                  method: 'ByteFrequency',
                  gain: 1,
                  res: 40,
                }
              }
            }
          ],
          dests: [{ id: 'volume' }]
        },
        elem
      )

      await webaudio.init({ file })

      return webaudio
    }

 





export class EternityTerminal extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    greeting: { type: String },
    data: { type: Object },
  };


  constructor() {
    super();
    this.greeting = "Hello";
    this.data = { name: "Evee" }; 
    
    this.initialized = false;

    this.context = {}
  }

  async initialize(e) {
    if (this.initialized === true) {
      return;
    }

    //const initial = await this.loadSound("https://westridgein.s3.us-east-1.amazonaws.com/sfx/message.mp3");
    
    //const ambient = await this.loadAmbientSound("kollamkadu");

    //initial.source.start();

    //ambient.source.start();

    

    this.initialized = true;
  }


  async loadAmbientSound(id) {
    const sound = await this.loadSound(sounds.ambient[id].url, { loop: true });
    return sound;
  }


  async loadMusic() {
    const audioEl = this.qs("audio");

    this.webaudio = await setupWebAudio(audioEl, audioEl.src);

    this.context.audio = this.webaudio
  }


   onMessage = async (e) => {
    
    const { detail } = e
    if ( detail.inView) {
        await this.playSound("chatMessageShow");
      } else {
        await this.playSound("chatMessageHide");
      }

      
    
  }

  attachListeners() {
    // called when evv send a message within page (chat message)
    //document.addEventListener("evv-message", this.onMessage);

    this.addEventListener("initialize", this.initialize);

    return  () => {
      document.removeEventListener("evv-message", this.onMessage);
      this.removeEventListener("initialize", this.initialize);
    }

  }


  async attachedCallback() {
    await this.loadMusic();

    const anim = (time) => {
      requestAnimationFrame(anim);

      this.webaudio.tick(time)
      
    }

    requestAnimationFrame(anim);
  }

  togglePlay(e) {
    const audio = this.qs("audio");
    if (audio.paused) {
      setTimeout(() => {
        this.fireEvent("auto-scroll", { detail: {} });
      }, 4000);
      audio.play();
      
    } else {
      audio.pause();
    }
    
  }
  
}

customElements.define("harmony-terminal", EternityTerminal);
