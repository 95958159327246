import { Element, html, css } from "../element";
import { animate, spring, scroll } from "motion";
import { mix, progress, wrap } from "@motionone/utils";

import createNodes from "./nodes";

import Canvas from "../../../utils/canvas";


const styles = css`
  :host {
    display: block;
    padding: 0;
	position: relative;
  }
  .container {
    width: 100%;
	height: 100%;
  }
  canvas {
    width: 100%;
	height: 100%;
  }
`;

const template = function (elem) {
  return html`
    <div class="container"> 
		<canvas id="canvas"></canvas>
    </div>
  `;
};


export class HarmonyCanvas extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    greeting: { type: String, attribute: false },
    data: { type: Object, attribute: false },
	canvas: { type: Object, attribute: false },
	context: { type: Object, attribute: true },
  };


  constructor() {
    super();
    this.initialized = false;
  }
  
  initialize() {
	this.initialized = true;
	const { width, height } = this.getBoundingClientRect();
	const canvasEl = this.qs("#canvas")
	this.canvas = new Canvas(canvasEl, width, height);

	this.draw()

	this.scrollY = 0
	

	scroll(({ y }) => {
		//console.log("scroll", y)
		this.scrollY = y;
	});
  }

  resize(e) {
	this.canvas.resize();
	this.setupNodes();
	this.draw()
  }


  draw() { 
	const {ctx, width, height } = this.canvas;
	
	//ctx.fillStyle = "black";
	//this.canvas.roundedRect(width/2, 100, 200, 100, 10);
	//ctx.fill()

	let x = 0;
	let y = 0;
	let radius = 50;
	let weight = 3;
	let currentAnimation;

	const startAnimation = (targetX, targetY) => {
		const originX = x;
		const originY = y;

		currentAnimation && currentAnimation.stop();
		currentAnimation = animate(
		(progress) => {
			x = mix(originX, targetX, progress);
			y = mix(originY, targetY, progress);
			//console.log("x", x, y)
			ctx.clearRect(0, 0, width, height);
			
			//this.canvas.roundedRect(width/2 + x, y, 200, 100, 10);
			//ctx.fill()
		},
		{
			easing: spring({ stiffness: 300, damping: 10, restDistance: 0.001 })
		}
		);
	};

	//startAnimation(200, 300);
  }


  attachEvents() {
	const resize = () => {
		this.resize()
	}
	window.addEventListener("resize", resize, { passive: true });

	return () => {
		window.removeEventListener("resize", resize, { passive: true });		
	}
  }
  

  setupNodes() {
	const { width, height } = this.canvas;

	const content = createNodes(this.canvas, {
		nodes: [
			//{ type: 'spectrum', x: width - 80, y: height - 110, width, height: 100, color: "#ff5230" },
			//{ type: 'visualizer', x: 400, y: height - 120, width, height: 100, color: "#ff5230" },
			//{ type: 'particles', count: 100, x: 0, 
			//		y: height - 60, width, height: 400, colors: ["#ff5230"],
			//	shape: 'threePointHeart' },
			{ type: 'harmony', x: 0, y: 0, width: 100, height: 400, colors: ["#ff5230"] }
		]
	});
	this.content = content
  }


  attachedCallback() {
	this.initialize();


	

	this.setupNodes();
	

	this.destroy = this.attachEvents();
	
    const anim = (time) => {
      requestAnimationFrame(anim);

	  //content.tick(time, this.context);
	  this.canvas.clearAll();
	  this.content.draw(time, this.canvas.ctx, this.context);
    };

    requestAnimationFrame(anim);
  
  }


  
}

customElements.define("harmony-canvas", HarmonyCanvas);




